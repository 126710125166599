import { create, ApisauceInstance } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { Store } from "../../stores";
import {
  ICollectResponse,
  IGetAntiForgeryTokenResponse,
  IGetQrCodeResult,
  IGetStartStateResponse,
  IGetTokenResponse,
} from "../models/Auth";
import { IConfig } from "../models/Config";
import { Environment } from "../models/Content/Enums";
import { objectToQueryString } from "../utils";
import { mockAntiforgeryToken, mockCollectResponse, mockConnectBankId, mockConnectToken, mockQrCodeResponse } from "./mock/santanderAuth";

export class SantanderAuthApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  authHeaders: any;

  antiForgeryToken: string = "";

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  scopes =
    "api.consumer.engagements.all.read " +
    "api.account.read offers.consumer.api.read " +
    "offers.consumer.api.write api.consumer.moneytransfers.read " +
    "api.consumer.moneytransfers.write " +
    "netbank.customer-compliance.read " +
    "netbank.customer-compliance.write " +
    "offline_access " +
    "netbank.chat.read " +
    "netbank.chat.write";

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        Accept: "application/json",
        "Accept-Language": "en",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
      };
      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.SANTANDER_AUTH_URL_V2,
        headers,
        timeout: 60000,
      });

      this.authHeaders = {
        auth: {
          username: config.SANTANDER_AUTH_CLIENT_ID,
          password: config.SANTANDER_AUTH_CLIENT_SECRET,
        },
      };
      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  setupAntiForgeryToken = async () => {
    const result = await this.client?.get<IGetAntiForgeryTokenResponse>(
      `bankid/start`,
      {
        version: "1.0.0",
        uidTypeHint: "none",
      },
      {
        auth: this.authHeaders.auth,
        withCredentials: true,
      }
    );

    if (result?.ok && result.data?.antiforgeryToken !== undefined) {
      this.antiForgeryToken = result?.data?.antiforgeryToken;
    }

    return result;
  };

  getStartState = async (isSameDevice: boolean = false, returnUrl: string) => {
    const antiForgeryResult = await this.setupAntiForgeryToken();

    if(!antiForgeryResult?.ok){
      return antiForgeryResult;
    }

    const isMobileApp = this.rootStore.platformStore?.isApplication();
    const userAgent = this.rootStore.commonService.getUserAgentHeader();
    let headers = {
      RequestVerificationToken: this.antiForgeryToken,
      ...(isMobileApp && { "User-Agent": userAgent })
    };

    const isMobile = this.rootStore.platformStore?.isMobile?.();
    const result = await this.client?.post<IGetStartStateResponse>(
      `bankid/auth`,
      {
        returnUrl: returnUrl,
        sameDevice: isSameDevice,
        tokenLogin: true,
        isMobile
      },
      {
        auth: this.authHeaders.auth,
        withCredentials: true,
        headers
      }
    );

    return result;
  };

  getQrCode = async (startState: string) =>
    this.client?.post<IGetQrCodeResult>(
      `bankid/qrcode`,
      {
        bankIdStartState: startState,
      },
      {
        auth: this.authHeaders.auth,
        withCredentials: true,
        headers: {
          RequestVerificationToken: this.antiForgeryToken,
        },
      }
    );

  collectResult = async (startState: string, returnUrl: string) =>
    this.client?.post<ICollectResponse>(
      `bankid/collect`,
      {
        BankIdStartState: startState,
        ReturnUrl: returnUrl,
      },
      {
        auth: this.authHeaders.auth,
        withCredentials: true,
        headers: {
          RequestVerificationToken: this.antiForgeryToken,
        },
      }
    );

  getToken = async (nounce: string) => {
    const details = {
      version: "1.0.0",
      grant_type: "bankid",
      scope: this.scopes,
      nounce,
    };
    const formBody = objectToQueryString(details);

    return this.client?.post<IGetTokenResponse>(`connect/token`, formBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      ...this.authHeaders
    });
  };

  refreshToken = async (refreshToken: string) => {
    const details = {
      version: "1.0.0",
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: this.scopes,
    };

    const formBody = objectToQueryString(details);

    return this.client?.post<IGetTokenResponse>(`connect/token`, formBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      ...this.authHeaders,
    });
  };

  revokeToken = async () => {
    if (!this.rootStore.securePersistentStore.accessToken) return null;

    const details = {
      token: this.rootStore.securePersistentStore.accessToken,
      token_type_hint: "access_token",
    };

    const formBody = objectToQueryString(details);

    return this.client?.post<string>(`connect/revocation`, formBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      ...this.authHeaders,
    });
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 500,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter;

    this.mock.onPost(`connect/token`).reply(200, mockConnectToken);
    this.mock.onPost(`bankid/auth`).reply(200, mockConnectToken);

    this.mock.onPost(`bankid/qrcode`).reply(200, mockQrCodeResponse);
    this.mock.onPost(`bankid/collect`).reply(200, mockCollectResponse);
    this.mock.onGet(`bankid/start`).reply(200, mockAntiforgeryToken);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
